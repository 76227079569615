import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Container, Row, Col } from "react-bootstrap"
import Fade from "react-reveal/Fade"

import Seo from "../components/Seo"
import { Layout } from "../components/Layout"

import imgSealing from "../images/top-sealing-caption.svg"
import imgWaterproof from "../images/top-watarproof-caption.svg"

const IndexPage: React.FC = () => {
  const pageName: string = "TOP";

  return (
    <Layout pageName={pageName}>
      <Seo title="メープル・シーリング | 富士・富士宮市のシーリング・防水工事はおまかせください！" />
      <section id="intro" className="top-intro contents-box">
        <Container>
          <h2 className="top-subtitle">
            雨漏り等でお悩みの方や<span className="d-none d-md-inline">、</span>
            <br className="d-md-none" />
            防水工事をお考えの方へ
          </h2>
          <Row className="mt-4">
            <Col md={6} className="wrapper mt-4">
              <div className="top-panel-head">
                <h3>
                  <img
                    src={imgSealing}
                    alt="シーリング工事。建物の素材または形状に適した防水施工により雨から守ります"
                  />
                </h3>
                <Link
                  to="/works#sealing"
                  className="top-panel-btn"
                  aria-label="詳しく見る"
                >
                  詳しく見る
                </Link>
              </div>
              <div className="top-panel-item">
                <StaticImage
                  src="../images/top-sealing.jpg"
                  alt="シーリング工事風景"
                  className="img-fit"
                />
              </div>
            </Col>
            <Col md={6} className="wrapper mt-4">
              <div className="top-panel-head">
                <h3>
                  <img
                    src={imgWaterproof}
                    alt="各種防水工事。建物の素材または形状に適した防水施工により雨から守ります"
                  />
                </h3>
                <Link
                  to="/works#waterproof"
                  className="top-panel-btn"
                  aria-label="詳しく見る"
                >
                  詳しく見る
                </Link>
              </div>
              <div className="top-panel-item">
                <StaticImage
                  src="../images/top-watarproof.jpg"
                  alt="防水工事風景"
                  className="img-fit"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="top-condition mt-5">
        <Container className="contents-box">
          <Fade bottom duration={500} distance="30px">
            <h2 className="top-subtitle">
              こんな症状やお悩みが
              <br className="d-sm-none" />
              ございませんか？
            </h2>
          </Fade>
          <Row className="mt-4">
            <Col xs={6} md={3} className="mt-4">
              <Fade bottom duration={500} delay={200} distance="30px">
                <StaticImage
                  layout="fullWidth"
                  src="../images/top-condition-crack.png"
                  alt="外壁のひび割れ"
                  className="img-flame-b"
                />
                <h3 className="top-condition-caption">外壁のひび割れ</h3>
              </Fade>
            </Col>
            <Col xs={6} md={3} className="mt-4">
              <Fade bottom duration={500} delay={400} distance="30px">
                <StaticImage
                  layout="fullWidth"
                  src="../images/top-condition-leak.png"
                  alt="天井・壁のシミ"
                  className="img-flame-b"
                />
                <h3 className="top-condition-caption">天井・壁のシミ</h3>
              </Fade>
            </Col>
            <Col xs={6} md={3} className="mt-4">
              <Fade bottom duration={500} delay={600} distance="30px">
                <StaticImage
                  layout="fullWidth"
                  src="../images/top-condition-mold.png"
                  alt="コーキング劣化"
                  className="img-flame-b"
                />
                <h3 className="top-condition-caption">コーキング劣化</h3>
              </Fade>
            </Col>
            <Col xs={6} md={3} className="mt-4">
              <Fade bottom duration={500} delay={800} distance="30px">
                <StaticImage
                  layout="fullWidth"
                  src="../images/top-condition-deterioration.png"
                  alt="防水層の劣化"
                  className="img-flame-b"
                />
                <h3 className="top-condition-caption">防水層の劣化</h3>
              </Fade>
            </Col>
          </Row>
          <div className="row wrapper mt-2">
            <div className="col-md-6 mt-4">
              <div className="top-condition-checkbox">
                <p className="top-condition-checklist">
                  最近<mark className="top-condition-marker">雨漏りする</mark>
                  ようになった
                </p>
              </div>
            </div>
            <div className="col-md-6 mt-4">
              <div className="top-condition-checkbox">
                <p className="top-condition-checklist">
                  外壁の
                  <mark className="top-condition-marker">ひび割れ・変色</mark>
                  が気になる
                </p>
              </div>
            </div>
            <div className="col-md-6 mt-4">
              <div className="top-condition-checkbox">
                <p className="top-condition-checklist">
                  キッチン・お風呂場など水回りのコーキングの
                  <mark className="top-condition-marker">カビ・はがれ</mark>
                  が気になる
                </p>
              </div>
            </div>
            <div className="col-md-6 mt-4">
              <div className="top-condition-checkbox">
                <p className="top-condition-checklist">
                  <mark className="top-condition-marker">築10年</mark>
                  ・・・もう一度防水工事をしたほうがいいのかな？
                </p>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <Container>
        <section className="top-advantage contents-box">
          <Fade bottom duration={500} distance="30px">
            <h2 className="top-subtitle">当社の3つの強み</h2>
          </Fade>
          <Row className="mt-4">
            <Col md={4} className="my-4">
              <Fade bottom duration={500} delay={200} distance="30px">
                <StaticImage
                  src="../images/top-advantage-tech.png"
                  alt="確かな技術"
                  className="img-flame-g"
                />
                <h3 className="top-advantage-subtitle">確かな技術</h3>
                <p className="my-2">
                  これまで25年以上の期間、一般住宅や高層マンションから、学校・工場・病院などの大型施設に至るまで様々なタイプの建物のシーリング工事・防水工事に携わってまいりました。
                  <br />
                  1級技能士として、長年の知識・豊富な経験を生かして、建物に合わせた、お客様にとって最善の施工を致します。
                </p>
              </Fade>
            </Col>
            <Col md={4} className="my-4">
              <Fade bottom duration={500} delay={400} distance="30px">
                <StaticImage
                  src="../images/top-advantage-speedy.png"
                  alt="迅速な対応"
                  className="img-flame-g"
                />
                <h3 className="top-advantage-subtitle">迅速な対応</h3>
                <p className="my-2">
                  当社では、富士・富士宮市を中心に、主にシーリング工事による雨漏り補修や防水工事を行っております。急な雨漏りですぐに対応してほしい・・・外壁のひび割れが気になるのでできるだけ早く調査してほしい・・・など、お客様のご要望に迅速に対応致します。
                </p>
              </Fade>
            </Col>
            <Col md={4} className="my-4">
              <Fade bottom duration={500} delay={600} distance="30px">
                <StaticImage
                  src="../images/top-advantage-price.png"
                  alt="適正価格"
                  className="img-flame-g"
                />
                <h3 className="top-advantage-subtitle">適正価格</h3>
                <p className="my-2">
                  ご相談・調査～お引き渡しまで、全て当社が引き受けますので、中間業者による余計な費用の心配はありません。また、お問い合わせをいただいてから見積り・契約までの費用はかかりませんのでご安心ください。
                  ご相談・調査やお見積りだけでも喜んで伺いますので、お気軽にお問い合わせください。
                </p>
              </Fade>
            </Col>
          </Row>
        </section>
      </Container>
    </Layout>
  )
}

export default IndexPage
